@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn {
    @apply border-2 border-black/25 rounded-xl py-2 px-3 font-semibold disabled:cursor-not-allowed disabled:opacity-50;
  }
  .btn.btn-primary {
    @apply text-white bg-blue-500 bg-opacity-80 hover:bg-opacity-100;
  }
  .btn.btn-secondary {
    @apply text-gray-600 bg-gray-200 bg-opacity-80 hover:bg-opacity-100;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply bg-slate-400;
}

h1 {
  font-family: 'Satisfy', serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
